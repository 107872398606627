import React, { useContext } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import { SolutionsSection } from "./services-page";
import '../style/product-style.sass';
import useWindowSize from '../hooks/useWindowSize';
import PageIntroSection from "../components/PageIntroSection";
import bg from "../../static/img/honey-border-bg.png"
import UseGoToSection from "../components/UseGoToSection";
import { Link } from "gatsby";
import { GlobalContext } from '../context/GlobalContext';
import productsPageSectionVideo from '../../static/videos/products-page-video.mp4';

//Products Component 2

const TitleFragment = ({ fragment, color }) => (
  <span style={{ color }}>{fragment + " "}</span>
);

const ProductsC2 = ({ mainpitch }) => {
  let sectionImage = getImage(mainpitch.img)
  const context = useContext(GlobalContext);
  const handleClick = async (e, newPath, newClass) => {
    await context.setState({ path: newPath, section: newClass });
  }
  return (
    <section className="section2" style={{display: 'flex', justifyContent: 'center'}}>
      <div className="products-c2-container fit-page">
        <div className="products-c2-sub-1">
          <GatsbyImage image={sectionImage} alt="Image Loading..."/>
        </div>
        <div className="products-c2-sub-2">
          <div className='products-c2-sub-2-h'>
              {mainpitch.title.map((item,i) => (
                <TitleFragment key={i} {...item}/>
              ))}
          </div>
          <p className="products-c2-sub-2-p1">{mainpitch.description}</p>
          <Link to="/contact"><button className="products-c2-sub-2-button" onClick={(e) => handleClick(e, "/contact", "section2")}>VIEW MORE</button></Link>
        </div>
      </div>
    </section>
  )

}

//Products Component 4
const ProductsC4 = ({ component4 }) => {
  const context = useContext(GlobalContext);
  const handleClick = async (e, newPath, newClass) => {
    await context.setState({ path: newPath, section: newClass });
  }
  return (
    <section className="section4">
      <div className='product4-container1 fit-page' style={{
          width: '100%',
          backgroundImage: `url(${component4.img1}), url(${component4.img3})`,
          backgroundPosition: 'left, right',
          backgroundRepeat: 'no-repeat, no-repeat',
          backgroundSize: '10% 100%, 10% 100%'
        }}>
          <div className="product4-content-section">
            <div className='product4-container-sub1'>
              {component4.sideVideo && <video disablePictureInPicture className="products-image2" src={productsPageSectionVideo} autoPlay loop playsInline muted></video>}
            </div>
            <div className='product4-container-sub2'>
              <h3 className='product-container-sub2-h has-text-weight-semibold'>{component4.heading}<span style={{ color: '#E65E27' }}> {component4.heading2}</span></h3>
              <p className='product-container-sub2-p1'></p>
              <p className='product-container-sub2-p2 has-text-weight-medium'>{component4.subheading}</p>
              <Link to="/contact"><button className='product4-childContainerBtn' onClick={(e) => handleClick(e, "/contact", "section2")}>SPEAK WITH AN EXPERT</button></Link>
            </div>
          </div>
      </div>
    </section>
  )
}


//Products Component 5
const ProductsC5 = ({ honeybee }) => {
  const { width } = useWindowSize();
  const mainContainer = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  }
  const lurmmain = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: width > 768 ? '60%': '80%',
    marginTop: width > 768 ? '2%': '20%',
    marginBottom: width > 768 ? '1%': '10%'
  }

  return (
    <>
      {
      width > 768 ? (
      <div className="section5" style={{ ...mainContainer }}>

        <div style={{ ...lurmmain }}>
          <h2 class='has-text-weight-bold' style={{ width: '100%', fontSize: 'clamp(1.8rem, 2.5vw,2.1rem)', textAlign: 'center', fontFamily:'Montserrat' }}>{honeybee.heading}<spam style={{ color: '#E65E27' }}> {honeybee.heading2}</spam></h2>
          <p class="has-text-weight-medium" style={{ width: width > 768 ? '69%': '100%', textAlign: 'center', fontSize: 'clamp(0.9rem, 1vw,1.2rem)' , color:'black' , fontFamily:'Montserrat'}}>{honeybee.description}</p>
        </div>

        <div className="honey-lurm1">
          {honeybee.subHeading1.map((item,i) => (
            <div style={{ width: '30%'}} key={i}>
                <h4 class='has-text-weight-semibold' style={{ color: item.color, fontSize: 'clamp(1rem, 1.3vw,1.75rem)' }}>{item.title}</h4>
                <p className='has-text-weight-medium' style={{ color: "black", fontSize: 'clamp(0.8rem, 0.9vw,1.1rem)' , lineHeight:  '1.6'}}>{item.des}</p>
            </div>
          ))}
        </div>

      <div className="honey-border-container">
        {honeybee.logos.map((item,i) => (
          <div className="honey-border"  style={{ position: item.position, top: item.top, left: item.left, right: item.right, bottom: item.bottom }} key={i}>
            <div className="honey-white">
              <GatsbyImage image={getImage(item.img)} alt='loading...' className="product-features-image" />
            </div>
          </div>
        ))}
        {honeybee.sticks.map((item,i) => (
          <GatsbyImage image={getImage(item.img)} alt='loading...' className="honey-bee-sticks" style={{ position: item.position, top: item.top, left: item.left, right: item.right, bottom: width > 768 ? item.bottom : `calc(${item.bottom} + 23%)`}} key={i}/>
        ))}
      </div>  

        <div className="honey-lurm2">
          {honeybee.subHeading2.map((item,i) => (
            <div style={{ width: '30%' }} key={i}>
                <h4 class='has-text-weight-semibold' style={{ color: item.color, fontSize: 'clamp(1rem, 1.3vw,1.75rem)' }}>{item.title}</h4>
                <p className='has-text-weight-medium' style={{ color: "black", fontSize: 'clamp(0.8rem, 0.9vw,1.1rem)', Width:'30%' , lineHeight:  '1.6'}}>{item.des}</p>
            </div>
          ))}
        </div>
      </div>
    ) : (
      <div class="section4" style={{display: 'flex' ,flexDirection: 'column', justifyContent: 'center' ,alignItems: 'center'}}>
        <div className="core-values-section core-values-section-products">
          <div className="core-values-content-section">
            <div style={{ ...lurmmain }}>
              <h2 class='has-text-weight-bold' style={{ color: 'black', width: '100%', fontSize: 'clamp(1.8rem, 2.5vw,2.1rem)', textAlign: 'center', fontFamily:'Montserrat' }}>{honeybee.heading}<spam style={{ color: '#E65E27' }}> {honeybee.heading2}</spam></h2>
              <p class="has-text-weight-medium" style={{ width: width > 768 ? '69%': '100%', textAlign: 'center', fontSize: 'clamp(0.9rem, 1vw,1.2rem)' , color:'black' , fontFamily:'Montserrat'}}>{honeybee.description}</p>
            </div>
            <div className="core-values-content core-values-content-products">
              {honeybee.coreValuesList.map((coreValue, index) => {
                let coreValueIcon = getImage(coreValue.icon)
                return (width > 768 ? true : true) && (
                  <>
                    {(width <= 768 && index) ? <hr /> : <></>}
                    <div key={index} className="core-value">
                      <div className="core-value-icon">
                        <GatsbyImage image={coreValueIcon} style={{width: width > 768 ? "55%": "clamp(3rem, 2vw, 20vw)"}} alt="Core Value"/>
                      </div>
                      <div className="core-value-container core-value-container-products">
                        <div className="core-value-title">{coreValue.title}</div>
                        <div className="core-value-description">{coreValue.description}</div>
                      </div>
                    </div>
                  </>
              )})}
            </div>
          </div>
        </div>
      </div>
    )
  }
    </>
    )
}


export const ProductPageTemplate = ({
  pageIntroData,
  mainpitch,
  solutions,
  component4,
  honeybee
}) => {
  return (
    <div>
      <UseGoToSection />
      <PageIntroSection pageIntro={pageIntroData} />
      <ProductsC2 mainpitch={mainpitch} />
      <SolutionsSection solutions={solutions} />
      <ProductsC4 component4={component4} />
      <ProductsC5 honeybee={honeybee} />
    </div>
  );
};

ProductPageTemplate.propTypes = {
  pageIntroData: PropTypes.shape({
    desktop: PropTypes.shape({
      title: PropTypes.arrayOf(
        PropTypes.shape({
          fragment: PropTypes.string.isRequired,
          color: PropTypes.string.isRequired,
        })
      ).isRequired,
      description: PropTypes.string.isRequired,
      buttonLabel: PropTypes.string.isRequired,
      buttonColor: PropTypes.string.isRequired,
      buttonPath: PropTypes.string.isRequired,
      styles: PropTypes.object.isRequired
    }).isRequired,
    mobile: PropTypes.shape({
      title: PropTypes.arrayOf(
        PropTypes.shape({
          fragment: PropTypes.string.isRequired,
          color: PropTypes.string.isRequired,
        })
      ).isRequired,
      backgroundImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      backgroundVideo: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      buttonLabel: PropTypes.string.isRequired,
      buttonColor: PropTypes.string.isRequired,
      buttonPath: PropTypes.string.isRequired,
      styles: PropTypes.object.isRequired
    }).isRequired
  }),
  mainpitch: PropTypes.object,
  solutions: PropTypes.shape({
    title: PropTypes.array,
    backgroundColor: PropTypes.string,
    solutionsList: PropTypes.array,
  }),
  component4: PropTypes.object,
  honeybee: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    heading2: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    subHeading1: PropTypes.array.isRequired,
    subHeading1: PropTypes.array.isRequired,
    logos: PropTypes.array.isRequired,
    sticks: PropTypes.array.isRequired,
    coreValuesList: PropTypes.array.isRequired,
  })
}

const ProductPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <ProductPageTemplate
        pageIntroData={frontmatter.pageIntroData}
        mainpitch={frontmatter.mainpitch}
        solutions={frontmatter.solutions}
        component4={frontmatter.component4}
        honeybee={frontmatter.component5}
      />
    </Layout>
  );
};

ProductPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ProductPage;

export const productPageQuery = graphql`
query ProductPageTemplate {
  markdownRemark(frontmatter: { templateKey: { eq: "product-page" } }) {
    frontmatter {
      pageIntroData {
        desktop {
          title {
            fragment
            color
          }
          backgroundVideo
          description
          buttonLabel
          buttonColor
          buttonPath
          styles {
            sectionHeight
            titleFontSize
            titleFontWeight
            titleLineHeight
            descriptionFontSize
            contentWidth
            contentPadding
            buttonPadding
            buttonFontSize
            buttonFontWeight
            videoLeft
          }
        }
        mobile {
          title {
            fragment
            color
          }
          backgroundVideo
          description
          buttonLabel
          buttonColor
          buttonPath
          styles {
            sectionHeight
            titleFontSize
            titleFontWeight
            titleLineHeight
            descriptionFontSize
            descriptionFontWeight
            descriptionLineHeight
            contentWidth
            contentPadding
          }
        }
      }
      mainpitch {
        img {
          childImageSharp {
            gatsbyImageData(quality: 100)
          }
        }
        title {
          fragment
          color
        }
        description
      }
      solutions {
          title {
            fragment
            color
          }
          backgroundColor
          title2color
          solutionsList {
            icon {
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
            title
            title1
            title2
            titleColor
            mobileTitle
            description
            path
          }
      }
      component4 {
        img1
        sideVideo
        imgInside
        heading
        heading2
        subheading
        img3
      }
      component5{
        heading
        heading2
        description
        subHeading1 {
          title
          des
          color
        }
        subHeading2 {
          title
          des
          color
        }
        logos {
          img {
            childImageSharp {
              gatsbyImageData(quality: 100 , height: 50 )
            }
          }
          position
          top
          bottom
          right
          left
        }
        sticks {
          img {
            childImageSharp {
              gatsbyImageData(quality: 100 , height: 264 )
            }
          }
          position
          top
          bottom
          right
          left
        }
        coreValuesList {
          icon {
            childImageSharp {
              gatsbyImageData(quality: 100)
            }
          }
          title
          description
        }
      } 
    }
  }
}
`;

